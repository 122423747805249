import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCartNewItemCount } from "../../helpers";
import { TOGGLE_CART_PAGE } from "../../Redux/actions/types";
import { instanceOf } from "prop-types";
import { cart, left_arrow_green, user } from "../../Assets";
import { StyledSVG } from "../../Utils/style";

function Header({
  backTitle,
  rtComponent,
  backPath,
  onTabProfile,
  style,
  hideHeader = false,
  showCartIcon = true,
  showIcons = true,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const loyaltyEnabled = table_detail?.Merchant?.loyalty_enabled;
  const cartCount = getCartNewItemCount(cart_detail);
  return (
    <header style={style}>
      <div className="container  ">
        {!hideHeader && (
          <div className="row d-flex align-items-center">
            <div
              onClick={() => {
                if (backPath && typeof backPath === "function") {
                  backPath();
                } else {
                  backPath && backPath !== ""
                    ? history.push(backPath.toString())
                    : history.goBack();
                }
              }}
              className="col-6"
            >
              <StyledSVG color={table_detail?.QrOrdering?.brand_highlight_color} src={left_arrow_green} width="8" />

              <a
                href="#"
                className="back"
                style={{color:table_detail?.QrOrdering?.brand_highlight_color}}
                onClick={() => {
                  if (backPath && typeof backPath === "function") {
                    backPath();
                  } else {
                    backPath && backPath !== ""
                      ? history.push(backPath.toString())
                      : history.goBack();
                  }
                }}
              >

                {" "}
                {backTitle}{" "}
              </a>
            </div>
            <div className="col-6 notify">
              {showIcons && (
                <>
                  {showCartIcon && (
                    // <a
                    //   href="#"
                    //   className="notification"
                    //   onClick={() => {
                    //     dispatch({ type: TOGGLE_CART_PAGE, payload: true });
                    //     history.push("/");
                    //   }}
                    // >
                    //   {cartCount > 0 ? (
                    //     <span className="counts"> {cartCount} </span>
                    //   ) : null}
                    // </a>
                    <div>
              <StyledSVG
              
                src={cart}
                className="notification"
                color={table_detail?.QrOrdering?.brand_main_color}
                width={20}
                onClick={() => {
                      dispatch({ type: TOGGLE_CART_PAGE, payload: true });
                      history.push("/");
                    }}
              ></StyledSVG>
              <a
                href="#_"
                style={{
                  color: table_detail?.QrOrdering?.brand_main_color,
                  marginRight: `${loyaltyEnabled ? "20px" : "0px"}`,
                }}
              >
                {cartCount > 0 ? (
                  <span className="counts cart-count"> {cartCount} </span>
                ) : null}
              </a>
            </div>
                  )}
                  {loyaltyEnabled && (
                    <a
                      href="#"
                      onClick={() => onTabProfile()}
                      className="profile"
                      style={{ backgroundColor:table_detail?.QrOrdering?.brand_main_color}}

                    >
                      <StyledSVG color={table_detail?.QrOrdering?.brand_main_color} src={user} width="16" />
                      {" "}
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {rtComponent ? rtComponent : null}
      </div>
    </header>
  );
}

export default Header;
