import { put, takeLatest, select, call } from "redux-saga/effects";
import { encode } from "base-64";
import axios from "axios";
import {
  SEND_OTP_DETAIL,
  SEND_OTP_DETAIL_RECEIVED,
  EMPTY_OTP_DETAIL,
  EMPTY_CARD_DETAIL,
  LOADING_STATUS,
  GET_USER_DETAIL,
  USER_DETAIL_RECEIVED,
  OTP_INPUT_RECEIVED,
  SHOW_OTP_INPUT_TOGGLE,
  GET_LOYALITY_DETAIL,
  CARD_DETAIL_RECEIVED,
  CART_DETAIL_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
  RECENT_ORDER_ID_RECEIVED,
  SEND_PHONE_DETAIL,
  PHONE_PIN_INPUT_RECEIVED,
  SHOW_PHONE_PIN_INPUT_TOGGLE,
  PHONE_AGREEMENT_DETAIL_RECEIVED,
  AVOCADO_DETAIL_RECEIVED,
  ORDER_HISTORY_DETAIL_RECEIVED,
  EMPTY_REORDER_ORDER_IDS,
  REORDER_ORDER_IDS_RECEIVED,
  CHANGE_ORDER_TIP,
  GET_XGATE_CUSTOMER,
  XGATE_DETAILS_RECEIVED,
  GET_XGATE_COUPON,
  XGATE_COUPON_RECEIVED,
  XGATE_POINTS_RECEIVED,
  GET_XGATE_POINTS,
  GET_COMO_CUSTOMER,
  COMO_DETAILS_RECEIVED,
  GET_CUSTOMER_DETAILS,
  SESSION_CREATE,
  APPLE_PAY_SESSION_DATA,
  FINSERVE_PAYMENT,
  LOGINPOPUP,
  GOOGLE_PAYMENT,
  GET_QR_MENU_ITEMS,
  TABLE_DETAIL_RECEIVED,
  FILTER_TAG_DETAIL_RECEIVED,
  CHANGE_TABLE_NOT_FOUND_MODAL,
  UPDATE_OUTLET_ID,
  EBER_LOGIN,
  EBER_DETAILS_RECEIVED,
  UPDATE_AUTHTOKEN,
} from "../actions/types";
import {
  showNotificationWithTimeout,
  getUserDetail,
  togglePhoneAgreementInput,
  updatePhoneAgreementInput,
  changePosCartDetail,
  emptyPosCartDetail,
  emptyXgateDetails,
  getXgateCustomer,
  emptyXgateCoupon,
  getXgateCoupon,
  emptyXgatePoints,
  changeRedirectHome,
  changePaymentDetail,
  emptyComoDetails,
  item86DetailRecieved,
} from "../actions";
import {
  apiCall,
  avocadoAuthToken,
  checkArray,
  checkNull,
  getCartSubmittedItems,
  getDeviceId,
  getEberURL,
  getPosCartSubmittedItems,
  getReOrderItemIds,
  getSessionStorageOrDefault,
  orderHistoryApi,
} from "../../helpers";
import { API } from "../../Utils/api";

const defaultErrorMsg = "Something went wrong! try again later.";
const base_url = process?.env?.REACT_APP_API_URL;
const como_base_url = process?.env?.REACT_APP_COMO_API_URL;

// console.log(" process?.env", process?.env);
// Advocado API
const ad_api_url = process?.env?.REACT_APP_AVOCADO_API_URL;
const ad_username = process?.env?.REACT_APP_AVOCADO_API_USERNAME;
const ad_password = process?.env?.REACT_APP_AVOCADO_API_PASSWORD;

let myHeaders = new Headers({
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
});
function* sendOtpSms(action) {
  console.log("base_url", base_url);
  // TODO
  // const authReducer = yield select((state) => state.authDetailReducer);
  // console.log("authReducer in saga", authReducer);
  const table_detail = yield select(
    (state) => state?.tableDetailReducer?.table_detail
  );
  const loyaltyPackage =
    table_detail?.Merchant?.MerchantLoyaltySetting?.LoyaltyPackage ?? null;
  yield put({ type: EMPTY_OTP_DETAIL });
  yield put({ type: LOADING_STATUS, status: true });
  const json = yield fetch(`${base_url}/qr_code_apis/verifyOtp`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      email: action?.email ?? "",
      otp: action?.otp ?? "",
      merchant_id: action?.merchantId ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("sendOtpSms failed : " + error.message));
  if (json?.status) {
    if (action?.otp !== undefined && action?.otp !== "") {
      yield put(
        getUserDetail({
          email: action?.email ?? "",
          merchantId: action?.merchantId ?? "",
          deviceId: action?.deviceId ?? "",
          tableNumber: action?.tableNumber ?? "",
        })
      );
      // if (loyaltyPackage.code == "xgate")
      //   yield put(
      //     getXgateCustomer(
      //       {
      //         email: action?.email ?? "",
      //         merchantId: action?.merchantId,
      //         tableNumber: action?.tableNumber,
      //       },
      //       false
      //     )
      //   );
    } else {
      yield put(
        showNotificationWithTimeout(
          `${json?.message ? json.message : "success"}`,
          "success"
        )
      );
      yield put({ type: SHOW_OTP_INPUT_TOGGLE, input: true });
      yield put({ type: OTP_INPUT_RECEIVED, input: "" });
    }
  } else {
    // yield put(
    //   showNotificationWithTimeout(
    //     `${json?.message ? json.message : defaultErrorMsg}`,
    //     "error"
    //   )
    // );
    yield put({ type: LOGINPOPUP, payload: true });
    console.log(
      `sendOtpSms error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* loginUser(action) {
  console.log("base_url213", base_url);
  const table_detail = yield select(
    (state) => state?.tableDetailReducer?.qr_order
  );
  yield put({ type: EMPTY_OTP_DETAIL });
  yield put({ type: EMPTY_REORDER_ORDER_IDS });
  if (!table_detail) {
    yield put({ type: EMPTY_RECENT_ORDER_ID });
  }

  yield put({ type: LOADING_STATUS, status: true });
  const deviceId = yield getDeviceId();
  const servicePeriodExpire = getSessionStorageOrDefault(
    "servicePeriodExpire",
    undefined
  );
  const json = yield fetch(`${base_url}/qr_code_apis/login`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      merchant_id: action?.merchantId ?? "",
      email: action?.email ?? "",
      deviceid: deviceId ?? "",
      table_number: action?.tableNumber ?? "",
      phone_detail: action?.phoneDetail ?? null,
      code: action?.code ?? "",
      date: servicePeriodExpire ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("loginUser failed : " + error.message));
  if (json?.status && json?.data) {
    console.log("json data ",json)
    const token = json?.token;
    const customerId = json?.data?.id;
    if (json?.data?.open_orders !== null) {
      const orders = json?.data?.open_orders;
     

      console.log("orders-----------------------------loginUser", orders);
      const table_detail = yield select(
        (state) => state?.tableDetailReducer?.table_detail
      );
      yield put({
        type: RECENT_ORDER_ID_RECEIVED,
        payload: orders[0]?.order_offline_id ?? "",
      });

      const items = getCartSubmittedItems(orders, table_detail);

      for (let i = 0; i < items.length; i++) {
        if (items[i].hasOwnProperty("is_server_fired")) {
          items[i].is_added =
            items[i].is_server_fired == "yes" || items[i].is_fired
              ? true
              : false;
        } else {
          items[i].is_added = true;
        }
      }

      if (items?.length > 0) {
        yield put({ type: CART_DETAIL_RECEIVED, cart_detail: items });
      }
      const posItems = getPosCartSubmittedItems(orders, table_detail);
      if (posItems?.length > 0) {
        yield put(
          changePosCartDetail({
            payment_type: orders[0]?.payment_type ?? [],
            split_type_name: orders[0]?.split_type_name ?? "",
            promotions: orders[0]?.promotions ?? [],
            items: posItems,
            transaction_id: orders[0]?.transaction_id ?? [],
            fiserv_order_id: orders[0]?.fiservorderId ?? [],
          })
        );
        yield put(
          changePaymentDetail({
            paymentType: checkArray(orders[0]?.payment_type) ? "split" : "",
            splitPaymentArr: orders[0]?.payment_type ?? [],
            wayToSplit: checkArray(orders[0]?.payment_type)
              ? orders[0]?.payment_type.length
              : 2,
            splitType: checkNull(orders[0]?.split_type_name, false)
              ? orders[0]?.split_type_name == "Split Evenly"
                ? 1
                : orders[0]?.split_type_name == "Split By Item"
                ? 2
                : 3
              : 0,
          })
        );
      } else {
        yield put(emptyPosCartDetail());
      }
      yield put({
        type: CHANGE_ORDER_TIP,
        payload: orders[0]?.tips ?? 0,
      });
    }
    yield put({
      type: UPDATE_AUTHTOKEN,
      payload: token,
    });
    yield put({ type: USER_DETAIL_RECEIVED, payload: json?.data });
    yield put(changeRedirectHome(true));
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : "success"}`,
        "success"
      )
    );
    /* Order history API */
    const orderHistoryData = yield orderHistoryApi({
      deviceId: deviceId,
      token: token,
      merchantId: action?.merchantId ?? "",
      customerId: customerId ?? "",
    });
    if (orderHistoryData?.status && orderHistoryData?.data) {
      yield put({
        type: ORDER_HISTORY_DETAIL_RECEIVED,
        payload: orderHistoryData?.data,
      });
      const reOrderIds = getReOrderItemIds(orderHistoryData?.data);
      if (reOrderIds.length > 0) {
        yield put({ type: REORDER_ORDER_IDS_RECEIVED, payload: reOrderIds });
      }
    } else {
      yield put(
        showNotificationWithTimeout(
          `${
            orderHistoryData?.message
              ? orderHistoryData.message
              : defaultErrorMsg
          }`,
          "error"
        )
      );
      console.log(
        `ORDER_HISTORY error : ${
          orderHistoryData?.message ? orderHistoryData.message : defaultErrorMsg
        }`
      );
    }
    /* Order history API END */
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `loginUser error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

// async function avocadoAuthToken() {
//   myHeaders.set(
//     "Authorization",
//     "Basic " + encode(ad_username + ":" + ad_password)
//   );
//   const json = await fetch(`${ad_api_url}/oauth2/tokenCredential`, {
//     method: "POST",
//     mode: "cors",
//     headers: myHeaders,
//     body: JSON.stringify({
//       grant_type: "client_credentials",
//     }),
//   })
//     .then((response) => response.json())
//     .then((json) => json)
//     .catch((error) => console.log("authToken failed : " + error.message));
//   return json;
// }

async function avocadoShowAggreement(token) {
  myHeaders.set("Authorization", "Bearer " + token);
  const json = await fetch(`${ad_api_url}/v1.1/merchants/pdpa`, {
    mode: "cors",
    headers: myHeaders,
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("authToken failed : " + error.message));
  return json;
}

function* sendPhoneDetail(action) {
  let phoneDetail = null;
  yield put({ type: LOADING_STATUS, status: true });
  const authToken = yield avocadoAuthToken();
  console.log("authTokenDetail:", authToken);
  if (authToken?.access_token?.value) {
    myHeaders.set("Authorization", "Bearer " + authToken?.access_token?.value);

    if (action?.phone_pin_input !== "") {
      const phonePinDetail = yield fetch(
        `${ad_api_url}/v1.1/customers/verify-pin`,
        {
          method: "POST",
          mode: "cors",
          headers: myHeaders,
          body: JSON.stringify({
            countryCallingCode: action?.phone_code,
            phoneNumber: action?.phone,
            pin: action?.phone_pin_input,
          }),
        }
      )
        .then((response) => response.json())
        .then((json) => json)
        .catch((error) => console.log("authToken failed : " + error.message));
      console.log("phonePinDetail:", phonePinDetail);
      if (
        phonePinDetail?.code !== 200 ||
        (phonePinDetail?.code == 200 && phonePinDetail?.data == false)
      ) {
        yield put(
          showNotificationWithTimeout(`Invalid pin provided!`, "error")
        );
        yield put({ type: LOADING_STATUS, status: false });
        return;
      }
    }

    if (action?.phone_agreement_input === true) {
      yield put(updatePhoneAgreementInput(true));
      phoneDetail = yield fetch(`${ad_api_url}/v1.1/customers/pdpa`, {
        method: "POST",
        mode: "cors",
        headers: myHeaders,
        body: JSON.stringify({
          countryCallingCode: action?.phone_code,
          phoneNumber: action?.phone,
          pdpa: 1,
        }),
      })
        .then((response) => response.json())
        .then((json) => json)
        .catch((error) => console.log("authToken failed : " + error.message));
      console.log("phonePdpaDetail:", phoneDetail);
      if (
        phoneDetail?.code !== 200 ||
        (phoneDetail?.code == 200 && phoneDetail?.status !== "success")
      ) {
        yield put(showNotificationWithTimeout(`${defaultErrorMsg}`, "error"));
        yield put({ type: LOADING_STATUS, status: false });
        return;
      }
    } else {
      phoneDetail = yield fetch(
        `${ad_api_url}/v1.1/customers/phone-number/${action?.phone_code}/${action?.phone}`,
        {
          mode: "cors",
          headers: myHeaders,
        }
      )
        .then((response) => response.json())
        .then((json) => json)
        .catch((error) => console.log("authToken failed : " + error.message));
      console.log("phoneDetail:", phoneDetail);
    }

    if (phoneDetail?.code == 200) {
      if (phoneDetail?.data?.pdpa == "0") {
        const authAgreement = yield avocadoShowAggreement(
          authToken?.access_token?.value
        );
        if (authAgreement?.code == 200 && authAgreement?.data?.pdpaContent) {
          yield put({
            type: PHONE_AGREEMENT_DETAIL_RECEIVED,
            input: authAgreement?.data?.pdpaContent ?? "",
          });
          yield put(togglePhoneAgreementInput(true));
        } else {
          yield put(
            showNotificationWithTimeout(
              `Unable to fetch agreement. Try again later!`,
              "error"
            )
          );
        }
      } else {
        yield put({
          type: AVOCADO_DETAIL_RECEIVED,
          payload: phoneDetail?.data ?? null,
        });
        yield put(
          getUserDetail({
            email: phoneDetail?.data?.email ?? "",
            merchantId: action?.merchantId ?? "",
            deviceId: action?.deviceId ?? "",
            tableNumber: action?.tableNumber ?? "",
            phoneDetail: phoneDetail?.data,
          })
        );
      }
    } else if (phoneDetail?.code == 501) {
      yield put({ type: SHOW_PHONE_PIN_INPUT_TOGGLE, input: true });
      yield put({ type: PHONE_PIN_INPUT_RECEIVED, input: "" });
      yield put(showNotificationWithTimeout(`Enter login pin!`, "success"));
    } else {
      yield put(
        showNotificationWithTimeout(`Invalid phone number provided!`, "error")
      );
    }
  }

  yield put({ type: LOADING_STATUS, status: false });
}

// XGATE
const xgateHeaders = new Headers({
  "Content-Type": "application/x-www-form-urlencoded",
  Accept: "application/json",
});
function* getXgateCustomerDetail(action) {
  console.log("base_url", base_url);
  const deviceId = yield getDeviceId();
  action.payload.deviceId = deviceId;
  yield put(emptyXgateDetails());
  yield put({ type: LOADING_STATUS, status: true });
  const details = {
    merchant_id: action?.payload?.merchantId ?? "",
    device_id: action?.payload?.deviceId ?? "",
  };
  if (checkNull(action?.payload?.email, false)) {
    details.email = action?.payload?.email;
  }
  if (checkNull(action?.payload?.mobile, false)) {
    details.mobile = action?.payload?.mobile;
  }
  if (checkNull(action?.payload?.card, false)) {
    details.card_number = action?.payload?.card;
  }
  let formBody = [];
  for (let property in details) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const customerDetails = yield fetch(
    `${base_url}/xgate_loyalty_apis/get_customer`,
    {
      method: "POST",
      mode: "cors",
      headers: xgateHeaders,
      body: formBody,
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("sendOtpSms failed : " + error.message));

  // create customer if not find
  // if (
  //   checkNull(action?.payload?.mobile, false) &&
  //   customerDetails?.data?.status &&
  //   customerDetails?.data?.status != "success"
  // ) {
  //   const createCustomer = yield fetch(
  //     `${base_url}/xgate_loyalty_apis/customer_create`,
  //     {
  //       method: "POST",
  //       mode: "cors",
  //       headers: xgateHeaders,
  //       body: formBody,
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((json) => json)
  //     .catch((error) =>
  //       console.log("createCustomer failed : " + error.message)
  //     );
  //   if (createCustomer?.data?.status == "success") {
  //     yield put(getXgateCustomer(action?.payload));
  //   }
  // }

  if (customerDetails?.data?.status == "success") {
    yield put({ type: XGATE_DETAILS_RECEIVED, payload: customerDetails?.data });
    yield put(
      getXgateCoupon({
        merchantId: action?.payload?.merchantId ?? "",
        code: customerDetails?.data?.membership?.code ?? "",
      })
    );
    if (action?.loginUser) {
      yield put(
        getUserDetail({
          email: customerDetails?.data?.email ?? "",
          merchantId: action?.payload?.merchantId ?? "",
          deviceId: action?.payload?.deviceId ?? "",
          tableNumber: action?.payload?.tableNumber ?? "",
          phoneDetail: customerDetails?.data?.mobile ?? null,
          code: customerDetails?.data?.membership?.code ?? "",
        })
      );
    }
  } else {
    const isMobile = checkNull(action?.payload?.mobile, false);
    const isCard = checkNull(action?.payload?.card, false);
    let errMsg = ` There is no member account with the provided ${
      isMobile && !isCard ? "mobile number" : ""
    }${!isMobile && isCard ? "loyalty member id" : ""}${
      isMobile && isCard ? "mobile number and loyalty member id" : ""
    }. Please try again`;
    if (action?.loginUser == false) {
      errMsg = `There is no loyalty member account with the provided email. Please setup`;
    }

    yield put({ type: LOGINPOPUP, payload: true });
    // yield put(showNotificationWithTimeout(`${errMsg}`, "error"));
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* getComoCustomerDetail(action) {
  myHeaders.set("Access-Control-Allow-Origin", "*");
  console.log("como_base_url", como_base_url);
  const deviceId = yield getDeviceId();
  action.payload.deviceId = deviceId;
  yield put(emptyComoDetails());
  yield put({ type: LOADING_STATUS, status: true });
  const customerObj = {};
  if (checkNull(action?.payload?.phone, false)) {
    customerObj.phoneNumber = action?.payload?.phone;
  } else if (checkNull(action?.payload?.email, false)) {
    customerObj.email = action?.payload?.email;
  } else if (checkNull(action?.payload?.appClientId, false)) {
    customerObj.appClientId = action?.payload?.appClientId;
  }
  const details = {
    merchant_id: action?.payload?.merchantId ?? "",
    device_identifier: "A821D7BB-669F-4E36-B179-838BE64BD940",
    customer: customerObj,
  };
  const customerDetails = yield fetch(
    `${como_base_url}/getMemberDetails?returnAssets=active&expand=assets.redeemable`,
    {
      // const customerDetails = yield fetch(`${como_base_url}/getMemberDetails`, {

      method: "POST",
      mode: "cors",
      headers: myHeaders,
      body: JSON.stringify(details),
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("sendOtpSms failed : " + error.message));

  if (customerDetails?.data?.status == "ok") {
    yield put({ type: COMO_DETAILS_RECEIVED, payload: customerDetails?.data });
    // yield put({
    //   type: GET_CUSTOMER_DETAILS,
    //   payload: {
    //     data: customerDetails?.data?.membership,
    //     merchantId: action?.payload?.merchantId,
    //   },
    // });

    const membershipObj = customerDetails?.data?.membership ?? null;
    if (action?.loginUser && membershipObj) {
      yield put(
        getUserDetail({
          email: membershipObj?.email ?? "",
          merchantId: action?.payload?.merchantId ?? "",
          deviceId: action?.payload?.deviceId ?? "",
          tableNumber: action?.payload?.tableNumber ?? "",
          phoneDetail: membershipObj?.phoneNumber ?? null,
          code: membershipObj?.comoMemberId ?? "",
        })
      );
    }
  } else {
    const isMobile = checkNull(action?.payload?.mobile, false);
    const isEmail = checkNull(action?.payload?.email, false);
    let keyMsg = isMobile
      ? "mobile number"
      : isEmail
      ? "email address"
      : "member ID";
    let errMsg = `There is no member account with the provided ${keyMsg}. Please try again`;
    // action.callback(true)
    if (customerObj.email) {
      yield put({ type: LOGINPOPUP, payload: true });
    } else {
      yield put(showNotificationWithTimeout(`${errMsg}`, "error"));
    }
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* getXgateCouponDetail(action) {
  console.log("base_url", base_url);
  const deviceId = yield getDeviceId();
  action.payload.deviceId = deviceId;
  yield put(emptyXgateCoupon());
  // yield put({ type: LOADING_STATUS, status: true });
  const details = {
    merchant_id: action?.payload?.merchantId ?? "",
    code: action?.payload?.code ?? "",
  };
  let formBody = [];
  for (let property in details) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const couponDetail = yield fetch(
    `${base_url}/xgate_loyalty_apis/get_coupon_codes`,
    {
      method: "POST",
      mode: "cors",
      headers: xgateHeaders,
      body: formBody,
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("get_coupon_codes failed : " + error.message)
    );

  if (couponDetail?.data?.status == "success") {
    yield put({ type: XGATE_COUPON_RECEIVED, payload: couponDetail?.data });
  } else {
    yield put(
      showNotificationWithTimeout(
        `${
          couponDetail?.data?.message
            ? couponDetail?.data?.message
            : defaultErrorMsg
        }`,
        "error"
      )
    );
  }
  // yield put({ type: LOADING_STATUS, status: false });
}

function* getXgatePointsDetail(action) {
  console.log("base_url", base_url);
  const deviceId = yield getDeviceId();
  action.payload.deviceId = deviceId;
  yield put(emptyXgatePoints());
  yield put({ type: LOADING_STATUS, status: true });
  const details = {
    ...action.payload,
  };
  let formBody = [];
  for (let property in details) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const pointsDetail = yield fetch(
    `${base_url}/xgate_loyalty_apis/redeemable_cash_preview`,
    {
      method: "POST",
      mode: "cors",
      headers: xgateHeaders,
      body: formBody,
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("redeemable_cash_preview failed : " + error.message)
    );

  if (pointsDetail?.data?.status == "success") {
    yield put({ type: XGATE_POINTS_RECEIVED, payload: pointsDetail?.data });
  } else {
    yield put(
      showNotificationWithTimeout(
        `${
          pointsDetail?.data?.message
            ? pointsDetail?.data?.message
            : defaultErrorMsg
        }`,
        "error"
      )
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

// loyality API

const loyality_api_url =
  "https://uatcrm.ascentis.com.sg/APIsWrapperIdentity/connect/token";
const loyality_instance_url =
  "https://uatcrm.ascentis.com.sg/APIsWrapperGateway/apiv1";
const loyality_grant_type = "client_credentials";
const loyality_scope = "crmapi";
const loyality_client_id = "HarrysBar_POS-GoGMGo\\testuser";
const loyality_client_secret = "qUDVNPN3MgFp3u7R";
let loyalityHeaders = new Headers({
  // "Content-Type": "application/x-www-form-urlencoded",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
});

function* loyalityCardCheck(action) {
  yield put({ type: LOADING_STATUS, status: true });
  yield put({ type: EMPTY_CARD_DETAIL });
  const deviceId = yield getDeviceId();
  const cardDetails = yield fetch(`${base_url}/ascentis/card_enquiry`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      CardNo: action?.card ?? "",
      CVC: "",
      // CVC: action?.cvv ?? "",
      merchant_id: action?.merchantId ?? "",
      deviceid: deviceId ?? "",
      table_number: action?.tableNumber ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("cardDetails failed : " + error.message));

  console.log("cardDetails", cardDetails);

  if (cardDetails?.status && cardDetails?.data) {
    console.log("cardDetails===>", cardDetails);
    if (cardDetails?.data?.orders !== null) {
      const orders = cardDetails?.data?.orders;
      const table_detail = yield select(
        (state) => state?.tableDetailReducer?.table_detail
      );
      yield put({
        type: RECENT_ORDER_ID_RECEIVED,
        payload: orders[0]?.order_offline_id ?? "",
      });
      // console.log("ordersssss===>", orders);
      // const items = getCartSubmittedItems(orders[0], table_detail);
      const items = getCartSubmittedItems(orders, table_detail);

      // console.log("ordersssss===>items", items);
      // debugger;
      console.log(
        "CART_DETAIL_RECEIVED ------------------------------------------------------ 3",
        items
      );

      if (items?.length > 0) {
        yield put({ type: CART_DETAIL_RECEIVED, cart_detail: items });
      }
      const posItems = getPosCartSubmittedItems(orders[0], table_detail);
      if (posItems?.length > 0) {
        yield put(
          changePosCartDetail({
            payment_type: orders?.payment_type ?? [],
            split_type_name: orders?.split_type_name ?? "",
            promotions: orders?.promotions ?? [],
            items: posItems,
            transaction_id: orders?.transaction_id ?? [],
            fiserv_order_id: orders?.fiservorderId ?? [],
          })
        );
        yield put(
          changePaymentDetail({
            paymentType: checkArray(orders?.payment_type) ? "split" : "",
            splitPaymentArr: orders?.payment_type ?? [],
            wayToSplit: checkArray(orders?.payment_type)
              ? orders?.payment_type.length
              : 2,
            splitType: checkNull(orders?.split_type_name, false)
              ? orders?.split_type_name == "Split Evenly"
                ? 1
                : orders?.split_type_name == "Split By Item"
                ? 2
                : 3
              : 0,
          })
        );
      } else {
        yield put(emptyPosCartDetail());
      }
      yield put({
        type: CHANGE_ORDER_TIP,
        payload: orders?.tips ?? 0,
      });
    }
    let userDetails = cardDetails?.data;
    userDetails.card_cvc = action?.cvv ?? "";
    yield put({ type: USER_DETAIL_RECEIVED, payload: userDetails });
    yield put(
      showNotificationWithTimeout(
        `${cardDetails?.message ? cardDetails.message : "success"}`,
        "success"
      )
    );
  } else {
    yield put(
      showNotificationWithTimeout(
        `${cardDetails?.message ? cardDetails.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `cardDetails error : ${
        cardDetails?.message ? cardDetails.message : defaultErrorMsg
      }`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* getCustomerDetails(action) {
  console.log("base_url", base_url);
  const deviceId = yield getDeviceId();
  action.payload.deviceId = deviceId;
  // yield put(emptyXgatePoints());
  yield put({ type: LOADING_STATUS, status: true });
  const details = {
    ...action.payload,
  };
  let formBody = JSON.stringify({
    merchant_id: action?.payload?.merchantId || "",
    email: action?.payload?.data?.email || "",
    contact_number: action?.payload?.data?.phoneNumber || "",
    mobile_number: action?.payload?.data?.phoneNumber || "",
    name: action?.payload?.data?.firstName || "",
    last_name: action?.payload?.data?.lastName || "",
    // gender:'',
    // nric:'',
    // passport:'',
    date_of_birth: action?.payload?.data?.birthday || "",
    // nationality:'',
    // block:'',
    // level:'',
    // unit:'',
    // street:'',
    // building:'',
    // post_code:'',
    // country:'',
    // address:'',
    loyalty_id: action?.payload?.data?.comoMemberId || "",
    loyalty_type: "como",
  });
  console.log("formBody of apple pay sessions ", formBody);
  const check = "POST";
  console.log("check===========", check);
  const pointsDetail = yield fetch(API.apple_Pay_Session, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: formBody,
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("redeemable_cash_preview failed : " + error.message)
    );

  if (pointsDetail?.data?.status == "success") {
    console.log(pointsDetail?.data, "pointsDetail?.data");
    // yield put({ type: XGATE_POINTS_RECEIVED, payload: pointsDetail?.data });
  } else {
    yield put(
      showNotificationWithTimeout(
        `${
          pointsDetail?.data?.message
            ? pointsDetail?.data?.message
            : defaultErrorMsg
        }`,
        "error"
      )
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* createApplePaySession(action) {
  try {
    yield put({ type: LOADING_STATUS, status: true });
    let formBody = JSON.stringify({
      url: action.payload.url,
      merchant_id: action.payload.merchant_id,
    });
    console.log("formBody of apple pay sessions ", formBody);

    const sessionData = yield fetch(API.apple_Pay_Session, {
      method: "POST",
      headers: myHeaders,
      body: formBody,
    })
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) => console.log("error: " + error.message));
    if (sessionData?.code == 200) {
      action.callback(sessionData?.data);
    } else {
      yield put(
        showNotificationWithTimeout(
          `${
            sessionData?.data?.message
              ? sessionData?.data?.message
              : defaultErrorMsg
          }`,
          "error"
        )
      );
      console.log("error", sessionData?.data?.message);

      yield put({ type: LOADING_STATUS, status: false });
    }
  } catch (error) {
    console.log("error", error);
    // yield put(showNotificationWithTimeout(`${error}`, "error"));
    yield put({ type: LOADING_STATUS, status: false });
  } finally {
    yield put({ type: LOADING_STATUS, status: false });
  }
}

function* createFinservePayment(action) {
  const applePayHeader = new Headers({
    "Content-Type": "application/json",
    Accept: "application/json",
  });

  try {
    yield put({ type: LOADING_STATUS, status: true });
    console.log(
      action?.payload,
      " payload of createFinservePayment ========>>>>>>> "
    );
    const sessionData = yield fetch(API.appleFinServer_Payment, {
      method: "POST",
      mode: "cors",
      headers: applePayHeader,
      body: JSON.stringify(action?.payload),
    })
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) =>
        console.log("redeemable_cash_preview failed : " + error.message)
      );
    console.log("sessionData============>>>>>>>>>>>>>>>>", sessionData);
    if (sessionData?.status) {
      action.callback(sessionData?.data);
      yield put({ type: LOADING_STATUS, status: false });
    } else {
      action.callback(false);
      yield put(
        showNotificationWithTimeout(
          `${sessionData?.message ? sessionData?.message : defaultErrorMsg}`,
          "error"
        )
      );
    }
  } catch (error) {
    action.callback(false);
    yield put({ type: LOADING_STATUS, status: false });
  } finally {
    yield put({ type: LOADING_STATUS, status: false });
  }
}

function* createGooglePayment(action) {
  console.log(" action for the google google payment  ========>>>>>>> ");
  const googlePayHeader = new Headers({
    "Content-Type": "application/json",
    Accept: "application/json",
  });

  try {
    yield put({ type: LOADING_STATUS, status: true });

    const sessionData = yield fetch(API.google_FinServer_Payment, {
      method: "POST",
      mode: "cors",
      headers: googlePayHeader,
      body: JSON.stringify(action?.payload),
    })
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) =>
        console.log("redeemable_cash_preview failed : " + error.message)
      );
    console.log("sessionData============>>>>>>>>>>>>>>>>", sessionData);
    if (sessionData?.status) {
      action.callback(sessionData?.data);
      yield put({ type: LOADING_STATUS, status: false });
    } else {
      action.callback(false);
      yield put(
        showNotificationWithTimeout(
          `${sessionData?.message ? sessionData?.message : defaultErrorMsg}`,
          "error"
        )
      );
    }
  } catch (error) {
    action.callback(false);
    yield put({ type: LOADING_STATUS, status: false });
  } finally {
    yield put({ type: LOADING_STATUS, status: false });
  }
}

function* get_QR_Menu_List(action) {
  console.log("inside the get QR menu Item QR code ", action);
  try {
    yield put({ type: LOADING_STATUS, status: true });
    yield put({ type: UPDATE_OUTLET_ID, payload: action.payload.menu_qr });

    console.log("inside the get QR menu Item QR code ", action.payload.menu_qr);

    const json = yield fetch(API.get_Menu_List, {
      method: "POST",
      mode: "cors",
      headers: myHeaders,
      body: JSON.stringify(action?.payload),
    })
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) =>
        console.log("get_tagsDetail failed : " + error.message)
      );
    const tagsJson = yield fetch(`${base_url}/qr_code_apis/get_tags`, {
      mode: "cors",
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) =>
        console.log("get_tagsDetail failed : " + error.message)
      );
    console.log("sessionData============>>>>>>>>>>>>>>>>", json);
    if (json?.status && json?.data) {
      sessionStorage.setItem("isTableDataLoaded", JSON.stringify(true));
      sessionStorage.setItem(
        "servicePeriodExpire",
        JSON.stringify(json?.data?.service_period?.date)
      );
      yield put({ type: TABLE_DETAIL_RECEIVED, json: json?.data });
      // if (json?.data?.occupied == 1) {
      //   if (checkNull(server_user_detail?.id, false)) {
      //     if (
      //       checkNull(pos_cart_detail?.employee_id, false) &&
      //       parseInt(pos_cart_detail?.employee_id) > 0 &&
      //       pos_cart_detail?.employee_id != server_user_detail?.id
      //     )
      //       yield put({ type: CHANGE_TABLE_ALREADY_INUSE_MODAL, payload: true });
      //   } else {
      //     yield put({ type: CHANGE_TABLE_ALREADY_INUSE_MODAL, payload: true });
      //   }
      // }
      if (json?.data?.Quota) {
        yield put(
          item86DetailRecieved(
            json?.data?.Quota && checkArray(json?.data?.Quota)
              ? json?.data?.Quota
              : []
          )
        );
      }
      if (tagsJson?.status && tagsJson?.data)
        yield put({ type: FILTER_TAG_DETAIL_RECEIVED, json: tagsJson?.data });
    } else {
      if (json?.message !== undefined) {
        yield put({ type: CHANGE_TABLE_NOT_FOUND_MODAL, payload: true });
      }
      console.log(
        `fetchTableDetail error : ${
          json?.message ? json.message : defaultErrorMsg
        }`
      );
    }
  } catch (error) {
    action.callback(false);
    yield put({ type: LOADING_STATUS, status: false });
  } finally {
    yield put({ type: LOADING_STATUS, status: false });
  }
}

function* post_eber_login(action) {
  try {
    yield put({ type: LOADING_STATUS, status: true });
    

    console.log("action ====================== ", action?.payload);
    // console.log("action ====================== ", getEberURL(action?.payload));
    // const str = action?.payload?.merchant_id?`?merchant_id=`+action?.payload?.merchant_id:""+action?.payload?.email?`&email=${action?.payload?.email}`:""+
    // action?.payload?.phone?`&phone=${action?.payload?.phone}`:""+action?.payload?.external_member_id?`&external_member_id=${action?.payload?.external_member_id}`:"";

    const str = `${API.eber_Login}?merchant_id=${action?.payload?.merchant_id}
    ${action?.payload?.email?`&email=${action?.payload?.email}`:""}
    ${action?.payload?.qr_code?`&qr_code=${action?.payload?.qr_code}`:""}
    ${action?.payload?.phone?`&phone_code=SG&phone=${action?.payload?.phone}`:""}
    ${action?.payload?.external_member_id?`&external_member_id=${action?.payload?.external_member_id}`:""}&list_redeemable=1`
      console.log("str ======================== ",str)

    const json = yield fetch(str.replace(" ",""),
      {
        mode: "cors",
        headers: myHeaders,
      }
    )
    // const json = yield fetch(API.eber_Login+getEberURL(action?.payload),
    //   {
    //     mode: "cors",
    //     headers: myHeaders,
    //   }
    // )
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) =>
        console.log("get_tagsDetail failed : " + error.message)
      );
    // const tagsJson = yield fetch(`${base_url}/qr_code_apis/get_tags`, {
    //   mode: "cors",
    //   headers: myHeaders,
    // })

    console.log("sessionData============>>>>>>>>>>>>>>>>",  json?.data);
    if (json?.status && json?.data) {
      console.log("sessionData============>>>>>>>>>>>>>>>>", json?.data);

      yield put({ type: EBER_DETAILS_RECEIVED, payload: json?.data });

      // yield put({ type: GET_USER_DETAIL, payload: action });
      yield put(
        getUserDetail({
          email: json?.data?.email ?? "",
          merchantId: action?.payload?.merchant_id ?? "",
          deviceId: action?.payload?.deviceId ?? "",
          // tableNumber: action?.payload?.tableNumber ?? "",
          phoneDetail: action?.payload?.phone ?? null,
          // code: membershipObj?.comoMemberId ?? "",
        })
      );
    } else {
      console.log(
        `fetchTableDetail error : ${
          json?.message ? json.message : defaultErrorMsg
        }`
      );
      yield put(
        showNotificationWithTimeout(
          `${json?.message ? "your credential is incorrect " : defaultErrorMsg}`,
          "error"
        )
      );
    }
  } catch (error) {
    yield put({ type: LOADING_STATUS, status: false });
  } finally {
    yield put({ type: LOADING_STATUS, status: false });
  }
}

export default function* authDetailActionWatcher() {
  yield takeLatest(SEND_OTP_DETAIL, sendOtpSms);
  yield takeLatest(SEND_PHONE_DETAIL, sendPhoneDetail);
  yield takeLatest(GET_USER_DETAIL, loginUser);
  yield takeLatest(GET_LOYALITY_DETAIL, loyalityCardCheck);
  yield takeLatest(GET_XGATE_CUSTOMER, getXgateCustomerDetail);
  yield takeLatest(GET_COMO_CUSTOMER, getComoCustomerDetail);
  yield takeLatest(GET_XGATE_COUPON, getXgateCouponDetail);
  yield takeLatest(GET_XGATE_POINTS, getXgatePointsDetail);
  yield takeLatest(GET_CUSTOMER_DETAILS, getCustomerDetails);
  yield takeLatest(SESSION_CREATE, createApplePaySession);
  yield takeLatest(FINSERVE_PAYMENT, createFinservePayment);
  yield takeLatest(GOOGLE_PAYMENT, createGooglePayment);
  yield takeLatest(GET_QR_MENU_ITEMS, get_QR_Menu_List);
  yield takeLatest(EBER_LOGIN, post_eber_login);
}
